<template>
  <div class="category-grid-wrapper">
    <div class="category-grid">
      <CategoryGridItem
        v-for="category in categories"
        :key="category.id"
        :title="category.title"
        :description="category.description"
        :button-text="category.buttonText"
        :image="category.image"
        :class="category.class"
        :background="category.backgroundColor"
        @click="$emit('click', category.id)"
      />
      <!-- <div class="category-grid__row">
      <div class="category-grid__col category-grid__col--default">
        <slot name="banner-A" />
      </div>
      <div class="category-grid__col category-grid__col--default">
        <slot name="banner-B" />
      </div>
      <div class="category-grid__col category-grid__col--default">
        <slot name="banner-C" />
      </div>
      <div class="category-grid__col category-grid__col--default">
        <slot name="banner-D" />
      </div>
    </div> -->
    </div>
  </div>
</template>
<script>
import CategoryGridItem from "../molecules/CategoryGridItem";

export default {
  name: "CategoryGrid",
  components: {
    CategoryGridItem,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers";

.category-grid-wrapper {
  overflow: hidden;

  @include for-mobile {
    height: 8.75rem;
  }
}

.category-grid {
  --banner-align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 110%;
  margin: -15px;

  @include for-mobile {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
  }

  &__item-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--spacer-xs);
    margin: 15px;
  }

  &__row {
    display: var(--banner-grid-row-display, block);
    flex: var(--banner-grid-row-flex);
    margin: var(--banner-grid-row-margin, 0);
  }
  &__col {
    display: var(--banner-grid-col-display, block);
    flex: var(--banner-grid-col-flex);
    margin: var(--banner-grid-col-margin, 0);
    & + & {
      --banner-grid-col-margin: var(--spacer-sm) 0 0 0;
    }
  }
  @include for-desktop {
    --banner-grid-row-display: flex;
    --banner-grid-row-flex: 0 0 100%;
    --banner-grid-col-display: flex;
    &__row {
      --banner-grid-row-margin: 0;
      & + & {
        --banner-grid-row-margin: var(--spacer-xl) 0 0 0;
      }
    }
    &__col {
      --banner-grid-col-flex: 1;
      flex-wrap: wrap;
      --banner-grid-col-margin: 0;
      & + & {
        --banner-grid-col-margin: 0 0 0 var(--spacer-xl);
      }
      & > .sf-banner,
      & > a {
        display: flex;
        flex: 1;
      }
      &--small {
        --banner-grid-col-flex: 0 1 36%;
      }
      &--medium {
        --banner-grid-col-flex: 0 1 40%;
        --banner-container-width: 70%;
      }
      &--higher {
        --banner-height: 510px;
      }
      &--default {
        --banner-height: 400px;
      }
    }
  }
}
</style>
