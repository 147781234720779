<template>
  <div
    class="zo-textbox"
    :class="{
      'zo-textbox--has-text': !!value,
      'zo-textbox--invalid': !valid,
    }"
  >
    <div class="zo-textbox__wrapper">
      <textarea
        :id="name"
        v-focus
        v-bind="$attrs"
        :value="value"
        :required="required"
        :disabled="disabled"
        :name="name"
        v-on="listeners"
      ></textarea>
      <span class="zo-textbox__bar"></span>
      <label class="zo-textbox__label" :for="name">
        <!-- @slot Custom input label -->
        <slot name="label" v-bind="{ label }">{{ label }}</slot>
      </label>
    </div>
    <div class="zo-textbox__error-message">
      <transition name="fade">
        <!-- @slot Custom error message of form input -->
        <slot v-if="!valid" name="error-message" v-bind="{ errorMessage }">
          <span>{{ errorMessage }}</span></slot
        >
      </transition>
    </div>
  </div>
</template>
<script>
import { focus } from "../../utility/directives/focus-directive.js";
export default {
  name: "Textbox",
  directives: {
    focus,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
      description: "Native input required attribute",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Native input disabled attribute",
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers";
.zo-textbox {
  position: relative;
  box-sizing: border-box;
  width: var(--input-width);
  height: 100px;
  &__label {
    position: absolute;
    display: var(--input-label-display);
    top: var(--input-label-top, 50%);
    left: var(--input-label-left);
    color: var(--input-label-color, inherit);
    transform: var(
      --input-label-transform,
      translate3d(0, calc(var(--input-label-top, 50%) * -1), 0)
    );
    transition: var(
      --input-label-transition,
      top 150ms linear,
      font-size 150ms linear
    );
    &::after {
      content: var(--input-label-required);
      color: var(--input-label-color, var(--c-primary));
    }
    @include font(
      --input-label-font,
      var(--font-normal),
      var(--font-lg),
      1,
      var(--font-family-secondary)
    );
  }
  &__bar {
    position: relative;
    display: var(--input-bar-display, block);
    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      width: var(--input-bar-width, 0);
      height: 1px;
      background: var(--input-bar-background, var(--c-primary));
      transition: width 150ms linear;
      content: "";
    }
    &::before {
      left: 50%;
    }
    &::after {
      right: 50%;
    }
  }
  &__error-message {
    color: var(--input-error-message-color, var(--c-danger));
    height: calc(var(--font-xs) * 1.2);
    @include font(
      --input-error-message-font,
      var(--font-medium),
      var(--font-xs),
      1.2,
      var(--font-family-secondary)
    );
  }
  &__wrapper,
  textarea {
    width: 100%;
    height: 100%;
  }
  &__wrapper {
    position: relative;
    margin: var(--input-margin, 0 0 var(--spacer-xs) 0);
  }
  textarea {
    display: block;
    box-sizing: border-box;
    padding: var(--input-padding, var(--spacer-sm) 0 var(--spacer-xs) 0);
    @include border(--input-border, 0 0 1px 0, solid, var(--c-light));
    background: #cbf0f8;
    padding: 2rem 1rem;
    color: var(--input-color, var(--c-text));
    @include font(
      --input-font,
      var(--font-normal),
      var(--font-lg),
      1,
      var(--font-family-secondary)
    );
    text-align: var(--input-text-align);
    &:focus {
      & ~ * {
        --icon-color: var(--c-primary);
        --input-password-icon-background: var(--c-primary);
        --input-label-top: 0;
        --input-label-color: var(--c-primary);
        --input-label-font-size: var(--font-2xs);
        --input-bar-width: 50%;
        --input-error-message-color: var(--c-primary);
      }
    }
    &:required {
      & ~ * {
        --input-label-required: " *";
      }
    }
    &:disabled {
      --input-color: var(--c-text-disabled);
      /* add to provide color on iOS */
      -webkit-text-fill-color: var(--c-text-disabled);
      & ~ * {
        --icon-color: var(--text-disabled);
        --input-label-color: var(--c-text-disabled);
      }
    }
    &[type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
    &[type="search"] {
      -webkit-appearance: none;
    }
  }
  &__password-button {
    --icon-size: 12px;
    --button-padding: 0;
    --button-background: transparent;
    position: absolute;
    top: var(--input-icon-top, 50%);
    right: var(--input-icon-right, var(--spacer-xs));
    transform: translate3d(0, -50%, 0);
    &:hover {
      --button-background: transparent;
      --button-box-shadow: none;
    }
  }
  &__password-icon {
    --icon-width: var(--input-password-icon-width, 1.375rem);
    --icon-height: var(--input-password-icon-height, 0.8125rem);
    position: relative;
    &--hidden {
      --input-password-icon-scaleX: 1;
    }
    &::after {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background: var(--input-password-icon-background, var(--c-black));
      transform: translate3d(0, -50%, 0) rotate(45deg)
        scaleX(var(--input-password-icon-scaleX, 0));
      transition: transform 150ms linear;
      content: "";
    }
  }
  &--invalid {
    --input-border-color: var(--c-danger);
    input {
      &:focus {
        & ~ * {
          --input-bar-background: var(--c-danger);
          --input-label-color: var(--c-danger);
        }
      }
    }
  }
  &--has-text,
  input[type="date"] ~ *,
  input[type="time"] ~ *,
  input[type="image"] ~ *,
  input[type="file"] ~ * {
    --input-label-top: 0;
    --input-label-font-size: var(--font-2xs);
  }
  &--filled {
    --input-background: var(--c-light);
    --input-font-size: var(--font-xs);
    --input-padding: var(--spacer-xs);
    --input-label-left: var(--spacer-xs);
    --input-label-font-size: var(--font-xs);
    --input-label-color: var(--c-secondary-variant);
    --input-label-transition: color 150ms ease;
    &.zo-textbox--has-text {
      --input-label-display: none;
    }
    input {
      &:focus {
        & ~ * {
          --input-bar-width: unset;
          --input-label-top: 50%;
          --input-label-font-size: var(--font-xs);
          --input-label-color: var(--c-text);
        }
      }
    }
  }
}
</style>
