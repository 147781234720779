// import axios from "axios";
import { Module } from "vuex";
import CatalogState from "../types/CatalogState";
import catalogService from "@lib/services/catalog";

const unsub = {
  products: null,
  categories: null,
};

const getProductFromDbObject = (product, categoryName) => {
  const basePrice = product.price.mrp || product.price.regular;
  const discountedPrice = product.price.mrp
    ? product.price.special || product.price.regular
    : product.price.special || null;
  const dbImages = product.images || [];
  const images = [];
  for (const dbImage of dbImages) {
    if (dbImage.url) {
      const thumbnail = dbImage.thumbnail || dbImage.url;
      const url = dbImage.url;
      const image = {
        id: dbImage.id || images.length + 1,
        position: dbImage.position || images.length,
        thumbnail,
        url,
        alt: product.title,
      };
      images.push(image);
    }
  }
  if (!images.length) {
    images.push({
      id: 1,
      position: 1,
      thumbnail: null,
      url: null,
      alt: product.title,
    });
  }
  return {
    ...product,
    image: images.length ? images[0].thumbnail : null,
    images,
    category: categoryName || "Unnamed",
    basePrice,
    discountedPrice,
    finalPrice: discountedPrice || basePrice,
  };
};

export const CatalogStore: Module<CatalogState, any> = {
  namespaced: true,
  state: {
    list: [],
    categories: [],
    stockMap: {},
    fetchWaiting: true,
    fetchError: null,
    fetched: false,
  },
  getters: {
    catNameMap: (state) => {
      return state.categories.reduce((obj, { oId, name }) => {
        obj[oId] = name;
        return obj;
      }, {});
    },
    list: (state, getters, rootState, rootGetters) => {
      const catMap = getters.catNameMap;
      return state.list.map((x) => {
        return getProductFromDbObject(x, catMap[x.categoryId] || "Unnamed");
      });
    },
    categories: (state) => {
      const productsByCatId = state.list.reduce((obj, product) => {
        obj[product.categoryId] = true;
        return obj;
      }, {});
      const catMap = state.categories.reduce((obj, { oId, name }) => {
        obj[oId] = name;
        return obj;
      }, {});
      const categories = Object.keys(productsByCatId).map((id) => {
        const name = catMap[id] || "Unnamed";
        return { name, id };
      });
      return [{ name: "All", id: "all" }].concat(categories);
    },
    categoryBannerInfo: (state) => {
      const productsByCatId = state.list.reduce((obj, product) => {
        obj[product.categoryId] = true;
        return obj;
      }, {});
      const categories = [];
      for (const category of state.categories) {
        if (productsByCatId[category.oId]) {
          categories.push({
            title: category.name,
            colour: category.colour,
            id: category.oId,
            position: category.position,
            image: category.image || null,
          });
        }
      }
      categories.sort((a, b) => (a.position || 0) - (b.position || 0));
      return categories;
    },
    fetchWaiting: (state) => state.fetchWaiting,
    fetchError: (state) => state.fetchError,
  },
  mutations: {
    setFetchWaiting(state, payload) {
      state.fetchWaiting = payload;
      state.fetchError = null;
      state.fetched = payload === false;
    },
    setFetchError(state, payload) {
      state.fetchError = payload;
      state.fetchWaiting = false;
    },
    categoryDocChange(state, change) {
      const { newIndex, oldIndex, doc, type } = change;
      if (type === "added") {
        const category = doc.data();
        state.categories.splice(newIndex, 0, category);
      } else if (type === "modified") {
        const category = doc.data();
        state.categories.splice(oldIndex, 1);
        state.categories.splice(newIndex, 0, category);
      } else if (type === "removed") {
        state.categories.splice(oldIndex, 1);
      }
    },
    productDocChange(state, change) {
      const { newIndex, oldIndex, doc, type } = change;
      if (type === "added") {
        const product = doc.data();
        state.list.splice(newIndex, 0, product);
        state.stockMap[product.id] = product.stock;
      } else if (type === "modified") {
        const product = doc.data();
        state.list.splice(oldIndex, 1);
        state.list.splice(newIndex, 0, product);
        state.stockMap[product.id] = product.stock;
      } else if (type === "removed") {
        delete state.stockMap[state.list[oldIndex].id];
        state.list.splice(oldIndex, 1);
      }
    },
    setProducts(state, products) {
      state.list = products;
      for (let product of products) {
        state.stockMap[product.id] = product.stock;
      }
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    // fetchCategories({ commit, rootState }) {
    //   return new Promise((resolve, reject) => {
    //     let resolveOnce = () => {
    //       resolveOnce = () => {};
    //       resolve();
    //     };
    //     unsub.categories = db
    //       .collection("storefronts")
    //       .doc(rootState.storeId)
    //       .collection("category")
    //       .onSnapshot((ref) => {
    //         ref.docChanges().forEach((change) => {
    //           commit("categoryDocChange", change);
    //         });
    //         resolveOnce();
    //       }, reject);
    //   });
    // },
    // fetchProducts({ commit, rootState, dispatch }) {
    //   return new Promise((resolve, reject) => {
    //     let resolveOnce = () => {
    //       resolveOnce = () => {};
    //       resolve();
    //     };
    //     unsub.products = db
    //       .collection("storefronts")
    //       .doc(rootState.storeId)
    //       .collection("products")
    //       .orderBy("title")
    //       .onSnapshot((ref) => {
    //         ref.docChanges().forEach((change) => {
    //           commit("productDocChange", change);
    //           dispatch("cart/updateProduct", change, { root: true });
    //         });
    //         resolveOnce();
    //       }, reject);
    //   });
    // },
    async init({ commit, dispatch }) {
      await dispatch("fetch");
    },
    async fetch({ commit, rootState, dispatch, state }) {
      if (!rootState.storeId) {
        throw new Error("No store found");
      }
      if (!state.fetched) {
        commit("setFetchWaiting", true);
        try {
          // const fetchPromises = [];
          // fetchPromises.push(dispatch("fetchCategories"));
          // fetchPromises.push(dispatch("fetchProducts"));
          // await Promise.all(fetchPromises);
          const catalog = await catalogService.getAll(rootState.storeId);
          commit("setProducts", catalog.products);
          for (let product of catalog.products) {
            dispatch("cart/updateProduct", product, { root: true });
          }
          commit("setCategories", catalog.categories);
          commit("setFetchWaiting", false);
        } catch (e) {
          console.error(e);
          commit(
            "setFetchError",
            "An error occurred while fetching the product catalog. Please try again after sometime."
          );
        }
      }
    },
    async search({ commit }, filter) {},
    checkStock({ commit, state }, { productId, requiredStock }) {
      return state.stockMap[productId] >= requiredStock ? "ok" : "out_of_stock";
    },
    unsubInventory({ commit }) {
      if (unsub.products) {
        unsub.products();
      }
    },
    async getProduct({ state, getters }, variantId) {
      const product = getters.list.find((x) => x.variantId === variantId);
      return product;
    },
    async getVariants({ state, getters }, itemId) {
      return getters.list.filter((x) => x.itemId === itemId);
    },
  },
};
