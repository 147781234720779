module.exports = {
  environment: "staging",
  serverlessUrl: "https://sfstaging.zobaze.com/_/",
  mockStoreName: "testsf",
  authUrl: "https://zo-front-s-auth.web.app",
  serverUrl: "/",
  debugMode: true,
  firebaseConfig: {
    apiKey: "AIzaSyBIfgMQK5IDBxlBevfeZ-A2h8-aG4z3Yvs",
    authDomain: "zo-front-s.firebaseapp.com",
    databaseURL: "https://zo-front-s.firebaseio.com",
    projectId: "zo-front-s",
    storageBucket: "zo-front-s.appspot.com",
    messagingSenderId: "139795626805",
    appId: "1:139795626805:web:92cc9868792efdff366a7c",
  },
  fcmVapidKey:
    "BATZ5E2JEK8tMimmKJMaZIS9Mf42RpprZp8new3DSwMs7-HGCUwloW87if4i8CYzx256sVFdvq2Hn7EKMKkFsdE",
  version: "0.8",
};
