<template>
  <SfButton class="sf-button--pure install-pwa-button" @click="installPwa">
    <div class="animate" :class="{ 'animate-active': showInstallText }">
      <font-awesome-icon
        class="fa__icon header__icon"
        :icon="['fas', 'plus-square']"
        style="width: 1.25rem; margin: 0 4px 0 2px"
      />
      install
    </div>
  </SfButton>
</template>

<script>
import { SfButton } from "@lib/components";

import { mapGetters, mapActions } from "vuex";
import { ModalList } from "@theme/store/ui/modals";
import config from "@config/config";

export default {
  components: { SfButton },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
  },
  data() {
    return {
      showInstallText: false,
    };
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      self.showInstallText = !self.showInstallText;
    }, 4000);
    setTimeout(() => {
      self.showInstallText = !self.showInstallText;
    }, 8000);
  },
  methods: {
    ...mapActions("ui", {
      openModal: "openModal",
      triggerAddToHomeScreen: "triggerAddToHomeScreen",
    }),
    installPwa() {
      this.triggerAddToHomeScreen();
    },
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";
.install-pwa-button {
  position: relative;

  .animate {
    display: flex;
    position: relative;
    right: 0px;
    width: 26px;
    overflow: hidden;
    -webkit-transition: width 1s;
    transition: width 1s;
    white-space: nowrap;
    color: white;
    align-items: center;
  }

  .animate-active {
    width: 80px;
    -webkit-transition: width 1s;
    transition: width 1s;
    border: 1px solid white;
    border-radius: 5px;
    background: var(--c-primary);
    white-space: nowrap;
  }
}

.fa__icon {
  position: var(--icon-position, relative);
  display: flex;
  box-sizing: border-box;
  width: var(--icon-width, var(--icon-size, #{map-get($sizes, "sm")}));
  height: var(--icon-height, var(--icon-size, #{map-get($sizes, "sm")}));
  background: transparent;
  font-size: 1.15rem;
  color: var(--icon-color, #{map-get($icon-colors, "black")});

  &::before {
    padding: 5px;
  }
}
</style>
