<template>
  <div id="initPage">
    <div id="loading" v-if="loading">
      <div class="loader-container fixed">
        <div class="loader-inner-container fixed">
          <div class="spinner relative">
            <div
              class="double-bounce1 absolute w-100 brdr-circle bg-cl-th-success"
            />
            <div
              class="double-bounce2 absolute w-100 brdr-circle bg-cl-th-success"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="error" v-if="error">
      <div class="error">
        <div class="error__image">
          <img src="@theme/assets/error.svg" alt="" />
        </div>
        <header class="sf-heading error__message">
          <h2 class="sf-heading__title sf-heading__title--h2">
            {{ title }}
          </h2>
          <div class="sf-heading__subtitle">
            {{ subtitle }}
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$root.loading;
    },
    error() {
      return this.$root.error;
    },
    title() {
      if (this.$root.isConfigError) {
        return "Something went wrong ...";
      }
      return "We can't find the page";
    },
    subtitle() {
      if (this.$root.isConfigError) {
        return "We've noticed Internal Server Error while rendering this request.";
      }
      return "Unfortunately we can't find the page you are looking for.";
    },
  },
  async created() {
    if (window.pwaUpdates && window.pwaUpdates.available) {
      if (
        !window.pwaUpdates.registration ||
        !window.pwaUpdates.registration.waiting
      ) {
        return;
      }
      console.log("Forcing update");
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload();
    });
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";
.loader-container {
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
}

.loader-inner-container {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.loader-message-container {
  background-color: #4dba87, 0.75;
  opacity: 0.75;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
  height: 100%;
  opacity: 0.6;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sf-heading {
  box-sizing: border-box;
  padding: var(--heading-padding, 0 0 var(--spacer-xs) 0);
  text-align: var(--heading-text-align, center);
  &__title {
    margin: var(--heading-title-margin, 0);
    color: var(--heading-title-color, var(--c-text));
    @include font(
      --heading-title-font,
      var(--font-semibold),
      var(--h1-font-size),
      1.6,
      var(--font-family-secondary)
    );
    &--h2 {
      font-size: var(--heading-title-font-size, var(--h2-font-size));
      font-weight: var(--heading-title-font-weight, var(--font-normal));
    }
    &--h3 {
      font-size: var(--heading-title-font-size, var(--h3-font-size));
      font-weight: var(--heading-title-font-weight, var(--font-medium));
    }
    &--h4 {
      font-size: var(--heading-title-font-size, var(--h4-font-size));
      font-weight: var(--heading-title-font-weight, var(--font-normal));
    }
    &--h5 {
      font-size: var(--heading-title-font-size, var(--h5-font-size));
      font-weight: var(--heading-title-font-weight, var(--font-bold));
    }
    &--h6 {
      font-size: var(--heading-title-font-size, var(--h6-font-size));
      font-weight: var(--heading-title-font-weight, var(--font-normal));
    }
  }
  &__subtitle {
    margin: var(--heading-subtitle-margin);
    color: var(--heading-subtitle-color, var(--c-text));
    @include font(
      --heading-subtitle-font,
      var(--font-light),
      var(--font-xs),
      1.6,
      var(--font-family-secondary)
    );
  }
  @include for-desktop {
    --heading-padding: 0;
    --heading-border: 0;
    --heading-text-align: center;
  }
  &--underline {
    @include border(--heading-border, 0 0 1px 0, solid, var(--c-light));
  }
  &--left {
    --heading-text-align: left;
  }
  &--right {
    --heading-text-align: right;
  }
}
</style>
