<template>
  <div class="bottom-navigation">
    <SfBottomNavigation>
      <SfBottomNavigationItem
        v-for="item in navigationItems"
        :key="item.icon"
        :icon="item.icon"
        :label="item.label"
        :is-floating="item.isFloating"
        :is-active="isActive(item.icon)"
        icon-size="26px"
        @click.native="item.onClick"
      />

      <SfBottomNavigationItem
        class="account-navigation-icon"
        icon="account"
        label="Profile"
        :is-active="isActive('profile')"
        icon-size="26px"
      >
        <template #icon>
          <AccountIcon class="icon pointer" />
        </template>
      </SfBottomNavigationItem>

      <SfBottomNavigationItem
        class="cart-item"
        icon="cart"
        label="Cart"
        :is-active="false"
      >
        <template #icon>
          <FloatingCartIcon class="icon pointer" />
        </template>
      </SfBottomNavigationItem>
    </SfBottomNavigation>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { ModalList } from "@theme/store/ui/modals";
import { SfBottomNavigation } from "@lib/components";
import FloatingCartIcon from "@theme/components/atoms/FloatingCartIcon";
import AccountIcon from "@theme/components/atoms/AccountIcon";

export default {
  name: "BottomNavigation",
  components: {
    SfBottomNavigation,
    FloatingCartIcon,
    AccountIcon,
  },
  data() {
    return {
      navigationItems: [
        { icon: "home", label: this.$t("Home"), onClick: this.goToHome },
        { icon: "menu", label: this.$t("Catalog"), onClick: this.goToCatalog },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapState({
      isMobileMenu: (state) => state.ui.isMobileMenu,
      isSearchPanelVisible: (state) => state.ui.searchpanel,
    }),
    isActive() {
      return (icon) => {
        switch (icon) {
          case "home": {
            const isHomepage = this.$route.name === "home";
            return (
              isHomepage && !this.isMobileMenu && !this.isSearchPanelVisible
            );
          }
          case "menu": {
            return this.$route.name === "catalog";
          }
          case "profile": {
            return this.$route.name === "account";
          }
          default: {
            // we don't need to show active icon for profile and cart, because bottom navigation is below
            return false;
          }
        }
      };
    },
  },
  methods: {
    ...mapActions({
      openModal: "ui/openModal",
      openMicrocart: "ui/toggleMicrocart",
    }),
    goToHome() {
      this.$router.push({ name: "home" });
    },
    goToCatalog() {
      this.$router.push({ name: "catalog" });
    },
    goToSearch() {
      this.$store.commit("ui/closeMenu");

      this.$store.commit("ui/setSearchpanel", !this.isSearchPanelVisible);
    },
    goToCart() {
      this.openMicrocart();
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers/breakpoints";

.bottom-navigation {
  position: relative;
  z-index: 1;
  @include for-desktop() {
    display: none;
  }
  ::v-deep .sf-bottom-navigation {
    z-index: inherit;
    .sf-bottom-navigation-item {
      cursor: pointer;
    }
  }

  .cart-item {
    // --bottom-navigation-item-color: var(--c-primary);

    .sf-bottom-navigation-item__label {
      padding-left: 2px;
    }
  }
}
</style>
