<template>
  <div class="cart-product">
    <component
      :is="linkComponentTag"
      v-focus
      :href="linkComponentTag === 'a' ? link : undefined"
      :to="link && linkComponentTag !== 'a' ? link : undefined"
      class="cart-product__link cart-product__link--image"
    >
      <div class="cart-product__image-wrapper">
        <slot
          name="image"
          v-bind="{ image: product.image, title: product.title }"
        >
          <template v-if="Array.isArray(product.image)">
            <SfImage
              v-for="(picture, key) in product.image.slice(0, 2)"
              :key="key"
              class="cart-product__picture"
              :src="picture"
              :alt="title"
              :width="imageWidth"
              :height="imageHeight"
            />
          </template>
          <template v-else>
            <SfImage
              class="cart-product__image"
              :src="product.image"
              :alt="product.title"
              :width="imageWidth"
              :height="imageHeight"
            />
          </template>
        </slot>
      </div>
    </component>
    <div class="cart-product__main">
      <div class="cart-product__details">
        <component
          :is="linkComponentTag"
          :href="linkComponentTag === 'a' ? link : undefined"
          :to="link && linkComponentTag !== 'a' ? link : undefined"
          class="cart-product__link"
        >
          <slot name="title" v-bind="{ title: product.title }">
            <div class="cart-product__title">{{ product.title }}</div>
            <div
              class="cart-product__variant-name"
              v-if="product.variantName"
              v-text="product.variantName || ''"
            ></div>
          </slot>
        </component>
        <div class="cart-product__configuration">
          <slot name="configuration" />
        </div>
      </div>
      <div class="cart-product__actions-wrapper">
        <div class="flex-box-horizontal">
          <slot
            name="price"
            v-bind="{
              specialPrice: product.discountedPrice,
              regularPrice: product.basePrice,
            }"
          >
            <SfPrice
              v-if="product.basePrice != null"
              class="cart-product__price"
              :regular="product.basePrice | price"
              :special="product.discountedPrice | price"
            />
          </slot>
          <div
            v-if="allowEdit && !isOutOfStock"
            class="cart-product__add-to-cart"
          >
            <slot name="add-to-cart">
              <SfAddToCart
                :qty="product.qty"
                :product="product"
                :max="showStockErrors ? product.stock : 10000"
                class="cart-product__add-to-cart"
              />
            </slot>
          </div>
          <div v-if="isOutOfStock">
            <div class="cart-product__error">Out of stock</div>
            <SfButton
              class="sf-button--full-width sf-button--text cart-product__remove cart-product__remove--text"
              @click="removeHandler"
              >Remove</SfButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { focus } from "@lib/utility/directives/focus-directive.js";
import SfPrice from "../atoms/SfPrice/SfPrice.vue";
import SfIcon from "../atoms/SfIcon/SfIcon.vue";
import SfProperty from "../atoms/SfProperty/SfProperty.vue";
import SfRating from "../atoms/SfRating/SfRating.vue";
import SfImage from "../atoms/SfImage/SfImage.vue";
import SfCircleIcon from "../atoms/SfCircleIcon/SfCircleIcon.vue";
import SfButton from "../atoms/SfButton/SfButton.vue";
import SfAddToCart from "../molecules/SfAddToCart/SfAddToCart.vue";
import SfProductOption from "../molecules/SfProductOption/SfProductOption.vue";

export default {
  components: {
    SfPrice,
    SfRating,
    SfImage,
    SfCircleIcon,
    SfIcon,
    SfButton,
    SfAddToCart,
    SfProductOption,
    SfProperty,
  },
  directives: {
    focus: focus,
  },
  model: {
    prop: "qty",
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    imageWidth: {
      type: [String, Number],
      default: 140,
    },
    imageHeight: {
      type: [String, Number],
      default: 140,
    },
    link: {
      type: [String, Object],
      default: "",
    },
    linkTag: {
      type: String,
      default: undefined,
    },
    isAddedToCart: {
      type: Boolean,
      deafult: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: "heart",
    },
    isCartEnabled: {
      type: Boolean,
      default: true,
    },
    showStockErrors: {
      type: Boolean,
      default: true,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    linkComponentTag() {
      if (this.linkTag) {
        return this.linkTag;
      }
      if (this.link) {
        return typeof this.link === "object" || this.$router
          ? "router-link"
          : "a";
      }
      return "div";
    },
    errorMessage() {
      if (!this.showStockErrors) {
        return null;
      }
      if (this.product.stock === 0) {
        return "Out of stock";
      }
      if (this.product.qty > this.product.stock) {
        return "Stock not available";
      }
      return null;
    },
    isOutOfStock() {
      return this.showStockErrors && this.product.stock <= 0;
    },
  },
  methods: {
    removeHandler() {
      this.$emit("click:remove");
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers";

.cart-product {
  display: flex;
  box-sizing: border-box;
  flex: 0 0 100%;
  padding: var(--spacer-xs) 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &__link {
    color: inherit;
    text-decoration: inherit;
    &--image {
      flex: 0 0 var(--product-card-horizontal-image-width, 5.3125rem);
    }
  }
  &__image-wrapper {
    position: relative;
    display: flex;
    &:hover {
      --product-card-horizontal-image-opacity: 1;
      --product-card-horizontal-image-even-opacity: 1;
    }
  }
  &__image,
  &__image-placeholder,
  &__picture {
    transition: var(
      --product-card-horizontal-image-transition,
      opacity 150ms ease-in-out
    );
    opacity: var(--product-card-horizontal-image-opacity);
    &:nth-child(even) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: var(--product-card-horizontal-image-even-opacity, 0);
    }
    border-radius: 0.375rem;
  }
  &__main {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: var(--product-card-horizontal-flex-direction, column);
    padding: var(
      --product-card-horizontal-main-padding,
      0 0 0 var(--spacer-sm)
    );
    justify-content: space-between;
  }
  &__details,
  &__actions-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__actions-wrapper {
    flex: 1;
    align-items: var(--product-card-horizontal-actions-wrapper, flex-start);
    margin: var(--product-card-horizontal-actions-wrapper-margin, 0.5rem 0 0 0);
    justify-content: flex-end;

    .flex-box-horizontal {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  &__title {
    margin: 0;
    color: var(--c-text);
    @include font(
      --product-card-horizontal-title-font,
      var(--font-medium),
      var(--h4-font-size),
      1.6,
      var(--font-family-secondary)
    );
  }
  &__description {
    margin: var(
      --product-card-horizontal-description-margin,
      var(--spacer-sm) 0
    );

    @include font(
      --product-card-horizontal-description-font,
      var(--font-light),
      var(--font-base),
      1.6,
      var(--font-family-primary)
    );
  }

  &__variant-name {
    margin-bottom: var(--spacer-xs);
    display: inline-block;
    @include font(
      --collected-product-title-font,
      var(--font-normal),
      var(--font-sm),
      0.875,
      var(--font-family-secondary)
    );
  }

  &__error {
    margin-bottom: var(--spacer-xs);
    color: red;
    display: inline-block;
    @include font(
      --collected-product-title-font,
      var(--font-normal),
      var(--font-sm),
      1,
      var(--font-family-secondary)
    );

    &:before {
      content: "\200b";
    }
  }

  &__configuration {
    margin: var(--product-card-horizontal-configuration-margin, 0);
  }
  &__reviews {
    display: flex;
    align-items: center;
    margin: var(--product-card-horizontal-review-margin, auto 0 0 0);
    @include font(
      --product-card-horizontal-reviews-font,
      var(--font-light),
      var(--font-sm),
      1.4,
      var(--font-family-secondary)
    );
    &-count {
      color: var(--c-text);
      text-decoration: none;
      margin: 0 0 0 var(--spacer-xs);
    }
  }
  &__actions {
    margin: var(--product-card-horizontal-actions-margin, 0);
  }
  &__add-to-cart {
    margin: var(--product-card-horizontal-add-to-cart-margin, 0);
  }
  &__wishlist-icon {
    --icon-color: var(--c-dark-variant);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}
</style>
