<template>
  <div aria-labelledby="AddToCart" class="sf-add-to-cart">
    <SfQuantitySelector
      v-if="productQty > 0 || focused || product.isFraction"
      :qty="productQty"
      aria-label="Quantity"
      :disabled="disabled"
      :max="max"
      :min="min"
      :is-fraction="product.isFraction"
      class="sf-add-to-cart__select-quantity"
      @input="setQuantity($event)"
      @focus="focused = true"
      @blur="focused = false"
      @hit-max-stock="showMaxStockNotification"
    />
    <slot name="add-to-cart-btn">
      <!--@slot Custom content that will replace default Add to cart button design.-->
      <div
        class="sf-add-to-cart__container"
        v-if="productQty == 0 && !focused && !product.isFraction"
      >
        <SfButton
          id="AddToCart"
          class="sf-add-to-cart__add-button"
          :disabled="disabled"
          @click.native="addToCart()"
        >
          <font-awesome-icon :icon="['fas', 'plus']" /> <span>Add</span>
        </SfButton>
      </div>
    </slot>
  </div>
</template>
<script>
import SfButton from "../../atoms/SfButton/SfButton.vue";
import SfQuantitySelector from "../../atoms/SfQuantitySelector/SfQuantitySelector.vue";
import { mapGetters } from "vuex";
import { notifications } from "@lib/modules/cart/helpers";
export default {
  name: "SfAddToCart",
  components: {
    SfButton,
    SfQuantitySelector,
  },
  model: {
    prop: "qty",
  },
  data() {
    return {
      focused: false,
    };
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    qty: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: -1,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({ cartQtyMap: "cart/getCartQtyMap" }),
    productQty() {
      return this.cartQtyMap[this.product.id] || 0;
    },
    isProductDisabled() {
      return this.disabled || formatProductMessages(this.product.errors) !== "";
    },
  },
  methods: {
    async addToCart() {
      const diffLog = await this.$store.dispatch("cart/addItem", {
        productToAdd: Object.assign({}, this.product, { qty: 1 }),
      });
      diffLog.clientNotifications.forEach((notificationData) => {
        this.$store.dispatch(
          "notification/spawnNotification",
          notificationData,
          { root: true }
        );
      });
    },
    async setQuantity(qty) {
      if (this.productQty === 0 && qty > 0) {
        const diffLog = await this.$store.dispatch("cart/addItem", {
          productToAdd: Object.assign({}, this.product, { qty }),
        });
        diffLog.clientNotifications.forEach((notificationData) => {
          this.$store.dispatch(
            "notification/spawnNotification",
            notificationData,
            { root: true }
          );
        });
      }
      if (qty <= 0) {
        await this.$store.dispatch("cart/removeItem", {
          product: this.product,
        });
        this.$store.dispatch(
          "notification/spawnNotification",
          notifications.createNotification({
            type: "success",
            message: "item removed from cart",
          })
        );
      } else {
        await this.$store.dispatch("cart/updateQuantity", {
          product: this.product,
          qty: qty,
        });
      }
    },
    showMaxStockNotification() {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "danger",
          message: "Stock not available",
        })
      );
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/components/molecules/SfAddToCart.scss";
</style>
