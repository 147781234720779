import { userStore } from "./store";
import { StorefrontModule } from "@lib/appRegister";
import { StorageManager } from "@lib/utility/storageManager";
import * as types from "./store/mutation-types";

export const UserModule: StorefrontModule = async function ({ store }) {
  StorageManager.init("user");
  store.registerModule("user", userStore);
  await store.dispatch("user/load");

  store.subscribe((mutation, state) => {
    const type = mutation.type;

    if (type.endsWith(types.USER_INFO_LOADED)) {
      StorageManager.get("user").setItem("current-user", state.user.current);
    }

    if (type.endsWith(types.USER_ORDERS_HISTORY_LOADED)) {
      StorageManager.get("user").setItem(
        "orders-history",
        state.user.orders_history
      );
    }

    if (type.endsWith(types.USER_TOKEN_CHANGED)) {
      StorageManager.get("user").setItem("current-token", state.user.userToken);
      if (state.user.refreshToken) {
        StorageManager.get("user").setItem(
          "current-refresh-token",
          state.user.refreshToken
        );
      }
    }
  });
};
