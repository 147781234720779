import VueRouter from "vue-router";
const Product = () =>
  import(/* webpackChunkName: "vsf-product" */ "@theme/pages/product.vue");

const rootRoutes = [
  {
    path: "/",
    name: "cover",
    component: () =>
      import(/* webpackChunkName: "cover" */ "@theme/pages/cover.vue"),
    meta: { layout: "basic" },
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@theme/pages/home.vue"),
  },
  {
    path: "/catalog",
    name: "catalog",
    component: () =>
      import(/* webpackChunkName: "catalog" */ "@theme/pages/catalog.vue"),
    props: true,
  },
  {
    name: "product",
    path: "/p/:sku",
    component: Product,
    meta: { header: "basic" },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "@theme/pages/checkout2.vue"),
    meta: { header: "basic", hasBottomNav: false },
  },
  {
    path: "/checkout2",
    name: "checkout2",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "@theme/pages/checkout2.vue"),
    meta: { header: "basic", hasBottomNav: false },
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "@theme/pages/account.vue"),
    meta: { header: "none" },
  },
  {
    path: "/account-new",
    name: "account-new",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "@theme/pages/account-new.vue"),
    meta: { header: "none" },
  },
  {
    path: "/appointment",
    name: "appointment",
    component: () =>
      import(
        /* webpackChunkName: "appointments" */ "@theme/pages/appointment.vue"
      ),
    meta: { header: "basic" },
  },
  {
    path: "/appointment/success",
    name: "appointmentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "appointments" */ "@theme/pages/appointment/success.vue"
      ),
    meta: { header: "basic" },
  },
  {
    path: "/guest/view-order",
    name: "viewGuestOrder",
    component: () =>
      import(
        /* webpackChunkName: "guest" */ "@theme/pages/guest/view-order.vue"
      ),
    meta: { header: "none" },
  },
  {
    path: "/email-confirmed",
    name: "emailConfirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@theme/pages/email-confirmed.vue"
      ),
    meta: { layout: "basic" },
  },
  {
    path: "/*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@theme/pages/error.vue"),
    meta: { layout: "basic", header: "basic" },
  },
];

const RootRouter = new VueRouter({
  mode: "history",
  routes: rootRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (from && from.name === "product" && to && to.name === "catalog") {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default RootRouter;
