import { ActionTree } from "vuex";
import * as types from "./mutation-types";
import i18n from "@lib/i18n";
import RootState from "@lib/types/RootState";
import UserState from "../types/UserState";
import { Logger } from "@lib/utility/logger";
import { UserProfile } from "../types/UserProfile";
import onlineHelper from "@lib/utility/helpers/onlineHelper";
import EventBus from "@lib/plugins/event-bus";
import { StorageManager } from "@lib/utility/storageManager";
import UserService from "@lib/services/user";
import notificationsService from "@lib/services/notifications";
import { logNewSession } from "@lib/modules/analytics/services";

const actions: ActionTree<UserState, RootState> = {
  async load({ rootGetters, dispatch }) {
    const userStorage = StorageManager.get("user");
    const storedUser = await userStorage.getItem("current-user");
    const storedToken = await userStorage.getItem("current-token");
    if (storedUser && storedToken) {
      // Here I need to have access token, user info like name, phoneNumber, email
      await dispatch("onLogin", {
        accessToken: storedToken,
        userId: storedUser.id,
        name: storedUser.name,
        email: storedUser.email,
        phoneNumber: storedUser.phoneNumber,
      });
    } else {
      await notificationsService.attachUserToInstance(null);
    }
    await logNewSession(rootGetters.storeId);
  },
  async clearCurrentUser({ commit, dispatch }) {
    commit(types.USER_TOKEN_CHANGED, "");
    commit(types.USER_GROUP_TOKEN_CHANGED, "");
    commit(types.USER_GROUP_CHANGED, null);
    commit(types.USER_INFO_LOADED, null);
    await dispatch("order/onLogout", null, { root: true });
    await dispatch("cart/onLogout", null, { root: true });
    // dispatch("wishlist/clear", null, { root: true });
    // dispatch("compare/clear", null, { root: true });
    // dispatch("checkout/savePersonalDetails", {}, { root: true });
    // dispatch("checkout/saveShippingDetails", {}, { root: true });
    // dispatch("checkout/savePaymentDetails", {}, { root: true });
  },
  async onLogin(
    { commit, dispatch },
    { accessToken, userId, name, email, phoneNumber }
  ) {
    console.debug("User Logged in");
    commit(types.USER_INFO_LOADED, { id: userId, name, email, phoneNumber });
    commit(types.USER_TOKEN_CHANGED, { userToken: accessToken });
    await dispatch("cart/onLogin", { userId }, { root: true });
    await dispatch("order/onLogin", { userId }, { root: true });
    await dispatch("getOrdersHistory");
    await dispatch("getAddresses");
    await notificationsService.attachUserToInstance(userId);
  },
  async updateUserDetails(
    { commit, dispatch },
    { accessToken, userId, name, email, phoneNumber }
  ) {
    commit(types.USER_INFO_LOADED, { id: userId, name, email, phoneNumber });
    commit(types.USER_TOKEN_CHANGED, { userToken: accessToken });
  },
  async loginUsingPhone({ commit, dispatch }, { phoneNumber, password }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.loginUsingPhone(phoneNumber, password);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email || null,
        phoneNumber: user.phoneNumber || null,
      });
      return { success };
    } else {
      return { success, errorCode, errorMessage };
    }
  },
  async signupUsingPhone({ dispatch }, { name, phoneNumber, password, otp }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.signupUsingPhone(name, phoneNumber, password, otp);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email || null,
        phoneNumber: user.phoneNumber,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async loginUsingEmail({ commit, dispatch }, { email, password }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.loginUsingEmail(email, password);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber || null,
      });
      return { success };
    } else {
      return { success, errorCode, errorMessage };
    }
  },
  async signupUsingEmail({ dispatch }, { name, email, password }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.signupUsingEmail(name, email, password);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber || null,
      });
      return { success };
    } else {
      return { success, errorCode, errorMessage };
    }
  },
  async logout({ commit, dispatch }, {}) {
    console.debug("Logging out!");
    commit(types.USER_END_SESSION);
    await dispatch("clearCurrentUser");
    await notificationsService.attachUserToInstance(null);
  },
  async sendPasswordResetCodeToPhone({ commit }, phoneNumber) {
    await UserService.sendPasswordResetCodeToPhone(phoneNumber);
  },
  async sendPasswordResetCodeToEmail({ commit }, email) {
    await UserService.sendPasswordResetCodeToEmail(email);
  },
  async resetPhonePassword({ dispatch }, { phoneNumber, password, otp }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.resetPhonePassword(phoneNumber, password, otp);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email || null,
        phoneNumber: user.phoneNumber,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async resetEmailPassword({ dispatch }, { email, password, otp }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.resetEmailPassword(email, password, otp);
    if (authToken) {
      await dispatch("onLogin", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email || null,
        phoneNumber: user.phoneNumber,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async updateEmailAddress({ dispatch }, { email }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.updateEmailAddress(email);
    if (authToken) {
      await dispatch("updateUserDetails", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber || null,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async updatePhoneNumber({ dispatch }, { phoneNumber, otp }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.updatePhoneNumber(phoneNumber, otp);
    if (authToken) {
      await dispatch("updateUserDetails", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email || null,
        phoneNumber: user.phoneNumber,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async updateName({ dispatch }, { fullName }) {
    const {
      success,
      authToken,
      user,
      errorCode,
      errorMessage,
    } = await UserService.updateName(fullName);
    if (authToken) {
      await dispatch("updateUserDetails", {
        accessToken: authToken,
        userId: user.id,
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber || null,
      });
      return { success };
    } else {
      console.log({ success, errorCode, errorMessage });
      return { success, errorCode, errorMessage };
    }
  },
  async verifyPasswordResetCodeForPhone({ dispatch }, { phoneNumber, otp }) {
    const {
      success,
      errorCode,
      errorMessage,
    } = await UserService.verifyPasswordResetCodeForPhone(phoneNumber, otp);
    return { success, errorCode, errorMessage };
  },
  async verifyPasswordResetCodeForEmail({ dispatch }, { email, otp }) {
    const {
      success,
      errorCode,
      errorMessage,
    } = await UserService.verifyPasswordResetCodeForEmail(email, otp);
    return { success, errorCode, errorMessage };
  },
  async sendPhoneLoginOtp({ commit }, userPhoneNumber) {
    await UserService.sendPhoneLoginOtp(userPhoneNumber);
  },
  async sendEmailLoginOtp({ commit }, userEmail) {
    await UserService.sendEmailLoginOtp(userEmail);
  },
  async getOrdersHistory({ commit, getters, rootGetters }) {
    commit(types.USER_ORDERS_RESET);
    if (!getters.userId) {
      Logger.debug("No User token, user unauthorized", "user")();
      commit(types.USER_ORDERS_ERROR, "Invalid credentials");
      return Promise.resolve(null);
    }
    try {
      const orders = await UserService.getOrders(rootGetters.storeId);
      commit(types.USER_ORDERS_HISTORY_LOADED, orders);
    } catch (error) {
      commit(
        types.USER_ORDERS_ERROR,
        "Could not fetch your orders. This may be due to poor network connection."
      );
    }
  },
  async getAddresses({ commit, getters, rootGetters }) {
    if (!getters.userId) {
      Logger.debug("No User token, user unauthorized", "user")();
      return Promise.resolve(null);
    }
    const addresses = await UserService.getAddresses(rootGetters.storeId);
    commit(types.USER_ADDRESSES_LOADED, addresses);
  },
  async updateAddress({ dispatch, getters, rootGetters }, address) {
    if (!getters.userId) {
      Logger.debug("No User token, user unauthorized", "user")();
      return Promise.resolve(null);
    }
    await UserService.updateAddress(
      rootGetters.storeId,
      getters.userId,
      address
    );
    await dispatch("getAddresses");
  },
  async deleteAddress({ commit, getters, rootGetters }, addressId) {
    if (!getters.userId) {
      Logger.debug("No User token, user unauthorized", "user")();
      return Promise.resolve(null);
    }
    await UserService.deleteAddress(
      rootGetters.storeId,
      getters.userId,
      addressId
    );
    commit(types.USER_ADDRESS_DELETED, addressId);
  },
};

export default actions;
