<template>
  <div class="search-panel">
    <div v-if="noResultsMessage" class="no-results">
      {{ $t(noResultsMessage) }}
    </div>
    <div v-else class="container">
      <div class="categories desktop-only">
        <h1 class="categories__title">
          {{ $t("Categories") }}
        </h1>
        <SfList
          v-if="visibleProducts.length && categories.length > 1"
          class="categories__listing"
        >
          <SfListItem v-for="category in categories" :key="category.id">
            <SfMenuItem
              :class="{ selected: isCategorySelected(category) }"
              :label="category.name"
              icon=""
              @click="toggleCategory(category)"
            />
          </SfListItem>
        </SfList>
      </div>
      <div class="products">
        <h1 class="products__title">
          {{ $t("Product suggestions") }}
        </h1>
        <div class="products__grid" v-if="!isGroupedView">
          <ProductGridItem
            v-for="(product, index) in visibleProducts"
            :key="product.id"
            :style="{ '--index': index }"
            :product="product"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            :link="'/p/' + product.variantId"
            class="products__grid-item"
          />
        </div>
        <div class="products__grid" v-else>
          <CollectedProductGridItem
            v-for="(group, i) in groupedProducts"
            :key="group[0].itemId"
            :style="{ '--index': i }"
            :products="group"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            class="products__grid-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfList,
  SfMenuItem,
  ProductGridItem,
  CollectedProductGridItem,
} from "@lib/components";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const getGroupedProducts = (products) => {
  let groups = {};
  for (let product of products) {
    groups[product.itemId] = groups[product.itemId] || [];
    groups[product.itemId].push(product);
  }
  return Object.values(groups);
};

export default {
  name: "SearchPanel",
  components: {
    SfList,
    SfMenuItem,
    ProductGridItem,
    CollectedProductGridItem,
  },
  data() {
    return {
      selectedCategoryIds: [],
    };
  },
  props: {
    search: {
      type: String,
      required: true,
      default: "",
    },
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
    isGroupedView: {
      type: Boolean,
      required: true,
    },
    isCartEnabled: {
      type: Boolean,
      required: true,
    },
    showStockErrors: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    visibleProducts() {
      const productList = this.selectedCategoryIds.length
        ? this.products.filter((product) =>
            this.selectedCategoryIds.includes(product.categoryId)
          )
        : this.products;

      return productList;
    },
    noResultsMessage() {
      return this.search.length < 3
        ? "Searched term should consist of at least 3 characters."
        : !this.visibleProducts.length
        ? "No results were found."
        : "";
    },
    categories() {
      const distinctCategories = this.products
        .map((x) => ({ id: x.categoryId, name: x.category }))
        .reduce((result, category) => {
          result[category.id] = category;
          return result;
        }, {});

      return Object.values(distinctCategories);
    },
    groupedProducts() {
      return getGroupedProducts(this.visibleProducts);
    },
  },
  methods: {
    isCategorySelected(category) {
      return this.selectedCategoryIds.includes(category.id);
    },
    toggleCategory(category) {
      if (this.isCategorySelected(category)) {
        this.selectedCategoryIds = this.selectedCategoryIds.filter(
          (categoryId) => categoryId !== category.id
        );
      } else {
        this.selectedCategoryIds.push(category.id);
      }
    },
  },
  watch: {
    categories() {
      this.selectedCategoryIds = [];
    },
  },
  mounted() {
    disableBodyScroll(this.$el);
  },
  destroyed() {
    clearAllBodyScrollLocks();
  },
};
</script>

<style lang="scss" scoped>
@import "~@lib/styles/helpers/breakpoints";

.search-panel {
  position: fixed;
  left: 0;
  right: 0;
  top: var(--_header-height);
  background: var(--c-white);
  overflow: auto;
  max-height: calc(66vh - var(--_header-height));
  color: var(--c-black-lighten);

  @include for-mobile {
    top: 92px;
    max-height: calc(100vh - 92px - 3.75rem);
  }

  .container {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    max-width: 976px;
    margin: auto;
    @include for-desktop {
      border-top: 1px solid var(--c-light);
    }
    @include for-mobile {
      flex-direction: column;
    }
  }

  .categories {
    @include for-desktop {
      flex: 0 0 20%;
      padding-right: 3rem;
      border-right: 1px solid var(--c-light);
    }

    &__title {
      padding: 0;
      font-size: var(--font-lg);
      font-weight: 500;
      line-height: 3;
    }
    &__listing {
      @include for-desktop {
        margin-top: 2rem;
      }

      .sf-list__item {
        padding: 0.3rem 0;
      }
      .sf-menu-item.selected {
        --menu-item-font-weight: 500;
        text-decoration: underline;
        --menu-item-label-color: var(--c-primary);
      }
    }
  }

  .products {
    width: 100%;
    &__title {
      padding: 0;
      font-size: var(--font-lg);
      font-weight: 500;
      line-height: 3;
    }
    &__listing {
      display: flex;
      flex: 0 1 auto;
      flex-wrap: wrap;
      margin: -1rem -1rem 0;
    }
    &__product-card {
      --product-card-max-width: 200px;
      flex: 0 1 33%;
      min-width: calc(var(--product-card-max-width) * 0.8);
    }

    @include for-desktop {
      padding-left: 3rem;
    }

    &__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &-item {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 75%;

        @include for-desktop {
          flex: 0 1 33%;
        }
      }

      @include for-desktop {
        padding: var(--spacer-xs);
        margin-top: 2rem;
      }
    }
  }

  .no-results {
    height: 5rem;
    line-height: 5rem;
    display: flex;
    justify-content: center;
  }

  .load-more {
    margin: var(--spacer-xl) 0;
  }
}
</style>
