<template>
  <div class="banners">
    <div ref="glide" class="glide">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <li
            v-for="(banner, index) in banners"
            :key="index"
            class="glide__slide banner-item"
            :style="getStyle(banner)"
            @click="onButtonClick(banner)"
          >
            <div class="aspect-ratio-16-9">
              <div class="banner-item__wrapper aspect-ratio-16-9__content">
                <div class="banner-item__content">
                  <img v-if="banner.image" :src="banner.image" />
                  <div v-if="banner.subtitle" class="banner-item__subtitle">
                    {{ banner.subtitle }}
                  </div>
                  <h1 v-if="banner.title" class="banner-item__title">
                    {{ banner.title }}
                  </h1>
                  <div v-if="banner.buttonText" class="banner-item__button">
                    <SfButton class="color-secondary">
                      {{ banner.buttonText }}
                    </SfButton>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="numberOfPages > 1"
      @click="go('prev')"
      class="banners__arrow-control banners__arrow-control-left"
    >
      <SfArrow class="sf-arrow sf-arrow--transparent" aria-label="previous" />
    </div>
    <div
      v-if="numberOfPages > 1"
      @click="go('next')"
      class="banners__arrow-control banners__arrow-control-right"
    >
      <SfArrow
        class="sf-arrow sf-arrow--right sf-arrow--transparent"
        aria-label="next"
      />
    </div>
    <div v-if="numberOfPages > 1" class="banners__bullets">
      <SfBullets
        :total="numberOfPages"
        :current="page - 1"
        @click="go($event)"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SfArrow from "../atoms/SfArrow/SfArrow.vue";
import SfBullets from "../atoms/SfBullets/SfBullets.vue";
import Glide from "@glidejs/glide";
import SfButton from "../atoms/SfButton/SfButton";

export default {
  name: "SlidingBanners",
  components: {
    SfArrow,
    SfBullets,
    SfButton,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      glide: null,
      defaultOptions: {
        type: "slider",
        rewind: true,
        autoplay: 10000,
        perView: 1,
        gap: 0,
      },
    };
  },
  computed: {
    numberOfPages() {
      return this.banners.length;
    },
    page() {
      if (this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
  },
  mounted() {
    if (this.numberOfPages) {
      this.$nextTick(() => {
        const glide = new Glide(this.$refs.glide, this.defaultOptions);
        glide.mount();
        this.glide = glide;
      });
    }
  },
  methods: {
    getStyle(banner) {
      const image = banner.image;
      const background = banner.background;
      const style = {
        "--_banner-item-background-image": image.mobile
          ? `url(${image.mobile})`
          : `url(${image})`,
        "--_banner-item-background-desktop-image":
          image.desktop && `url(${image.desktop})`,
        "--_banner-item-background-color": background,
      };
      return {};
    },
    onButtonClick(banner) {
      const buttonLinkConfig = banner.link || {};
      if (buttonLinkConfig.type === "url") {
        window.open(buttonLinkConfig.urlValue, "_blank");
      } else if (buttonLinkConfig.type === "categories") {
        this.$router.push({
          name: "catalog",
          params: { categories: buttonLinkConfig.value },
        });
      } else if (buttonLinkConfig.type === "tags") {
        this.$router.push({
          name: "catalog",
          params: { tags: buttonLinkConfig.value },
        });
      }
    },
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@lib/styles/helpers";
.banner-item {
  @include background(
    --banner-item-background,
    var(--_banner-item-background-color, transparent),
    var(--_banner-item-background-image)
  );
  color: var(--banner-item-color, var(--c-text));
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;

  .aspect-ratio-16-9 {
    position: relative;
    width: 100%;
    padding-top: 50%;
    height: 0;

    &__content {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__wrapper {
    padding: var(--banner-item-padding, var(--spacer-sm));
    display: flex;
    flex-direction: var(--banner-item-flex-direction, row);
    align-items: var(--banner-item-align-items, center);
    justify-content: var(--banner-item-justify-content, flex-start);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: var(--banner-item-container-width, 50%);

    img {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      border-radius: 0.5rem;
    }
  }

  &__subtitle {
    margin: var(--banner-item-subtitle-margin, 0);
    color: var(--banner-item-subtitle-color, var(--c-gray));
    @include font(
      --banner-item-subtitle-font,
      var(--font-normal),
      var(--font-2xs),
      1.4,
      var(--font-family-secondary)
    );
    text-transform: var(--banner-item-subtitle-transform, uppercase);
  }
  &__title {
    margin: var(--banner-item-title-margin, var(--spacer-xs) 0 0 0);
    color: var(--banner-item-title-color, var(--c-text));
    @include font(
      --banner-item-title-font,
      var(--font-semibold),
      var(--h2-font-size),
      1.2,
      var(--font-family-secondary)
    );
    letter-spacing: 0.1px;
    text-transform: var(--banner-item-title-transform, none);
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
  }
  &__button {
    margin-top: 3rem;
    display: var(--banner-item-button-display, none);

    .sf-button {
      border-radius: 0.375rem;
    }
  }
  &--align-right {
    --banner-item-justify-content: flex-end;
  }
  @include for-desktop {
    --banner-item-padding: var(--spacer-2xl);
    --banner-item-background-image: var(
      --_banner-item-background-desktop-image,
      var(--_banner-item-background-image)
    );
    &__title {
      --banner-item-font-size: var(--h1-font-size);
      --banner-item-title-margin: var(--spacer-base) 0 var(--spacer-lg) 0;
    }
    &__button {
      --banner-item-button-display: block;
    }
  }

  @include for-tablet {
    --banner-item-padding: var(--spacer-xl);

    &__title {
      --banner-item-font-size: var(--h2-font-size);
      --banner-item-title-margin: var(--spacer-sm) 0 var(--spacer-base) 0;
    }
    &__button {
      --banner-item-button-display: block;
    }
  }

  @include for-mobile-lg {
    --banner-item-padding: var(--spacer-lg);
  }

  @include for-mobile-md {
    --banner-item-padding: var(--spacer-lg);
  }

  @include for-mobile-sm {
    --banner-item-padding: var(--spacer-base);
  }
}

.banners {
  position: relative;
  &__arrow-control {
    position: absolute;
    top: var(--banners-controls-top, 50%);
    transform: translate3d(0, -50%, 0);
    display: var(--banners-controls-display, none);
    box-sizing: border-box;
    justify-content: var(--banners-controls-justify-content, space-between);
    padding: var(--banners-controls-padding, 0 var(--spacer-sm));
  }

  &__arrow-control-left {
    left: 0;
  }

  &__arrow-control-right {
    right: 0;
  }

  &__bullets {
    position: absolute;
    bottom: var(--banners-bullets-bottom, var(--spacer-xl));
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    display: var(--banners-bullets-display, none);
  }
  .glide {
    &__slides {
      margin: 0;
    }
  }
  @include for-desktop {
    &__arrow-control {
      --banners-controls-display: flex;
    }
    &__bullets {
      --banners-bullets-display: flex;
    }
  }
}
</style>
