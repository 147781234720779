var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-input",class:{
    'sf-input--has-text': !!_vm.value,
    'sf-input--invalid': !_vm.valid,
  }},[_c('div',{staticClass:"sf-input__wrapper"},[_c('input',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],class:{ 'sf-input--is-password': _vm.isPassword },attrs:{"id":_vm.name,"required":_vm.required,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.inputType},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),_c('span',{staticClass:"sf-input__bar"}),_c('label',{staticClass:"sf-input__label",attrs:{"for":_vm.name}},[_vm._t("label",[_vm._v(_vm._s(_vm.label))],null,{ label: _vm.label })],2),(_vm.isPassword)?_vm._t("show-password",[_c('SfButton',{staticClass:"sf-input__password-button",attrs:{"type":"button","aria-label":"switch-visibility-password","aria-pressed":_vm.isPasswordVisible.toString()},on:{"click":_vm.switchVisibilityPassword}},[_c('SfIcon',{staticClass:"sf-input__password-icon",class:{
            'sf-input__password-icon--hidden': !_vm.isPasswordVisible,
          },attrs:{"icon":"show_password"}})],1)],null,{
        isPasswordVisible: _vm.isPasswordVisible,
        switchVisibilityPassword: _vm.switchVisibilityPassword,
      }):_vm._e()],2),_c('div',{staticClass:"sf-input__error-message"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.valid)?_vm._t("error-message",[_c('span',[_vm._v(_vm._s(_vm.errorMessage))])],null,{ errorMessage: _vm.errorMessage }):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }